import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button
} from 'semantic-ui-react'

const CardFoodListUX = (props: any) => {
    return(
      <div>
        <div
          id="CardFoodListUX-div-1">
          
          <Table
            data={props.foodList}
            getTrProps={props.foodListProps}
            headers={props.headers}
            id="CardFoodListUX-Table-4"
            keys={props.keys}
            minRows={10}
            showPagination={false}
            widths={props.widths}>
          </Table>
        </div>
        <div
          id="CardFoodListUX-div-2"
          style={{ display:"none",justifyContent:"flex-end",margin:"10px 0" }}>
          
          <Button
            color="blue"
            id="CardFoodListUX-Button-8"
            size="small">
            พิมพ์สรุปรายการทั้งหมด
          </Button>
          <Button
            color="yellow"
            id="CardFoodListUX-Button-9"
            size="small">
            ส่งห้องโภชนาการ
          </Button>
        </div>
      </div>
    )
}


export default CardFoodListUX

export const screenPropsDefault = {}

/* Date Time : Tue Mar 18 2025 15:40:41 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardFoodListUX-div-1"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardFoodListUX-div-2"
        },
        "style": {
          "type": "code",
          "value": "{ display:\"none\",justifyContent:\"flex-end\",margin:\"10px 0\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 4,
      "name": "Table",
      "parent": 1,
      "props": {
        "data": {
          "type": "code",
          "value": "props.foodList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.foodListProps"
        },
        "headers": {
          "type": "code",
          "value": "props.headers"
        },
        "id": {
          "type": "value",
          "value": "CardFoodListUX-Table-4"
        },
        "keys": {
          "type": "code",
          "value": "props.keys"
        },
        "minRows": {
          "type": "code",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "widths": {
          "type": "code",
          "value": "props.widths"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์สรุปรายการทั้งหมด"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "fluid": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardFoodListUX-Button-8"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ส่งห้องโภชนาการ"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "id": {
          "type": "value",
          "value": "CardFoodListUX-Button-9"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 9,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardFoodListUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
